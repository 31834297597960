// src/stores/authStore.ts
import { defineStore } from 'pinia'
import { tokenManager } from '../utils/token-manager'
import { login, logoutUser, registerUser, verifyEmail, verifyLogin } from '../utils/api-helpers'
import { ApiError } from '../utils/api-client'

interface AuthState {
  token: string | null
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    token: null,
  }),
  getters: {
    isAuthenticated: () => !!tokenManager.getToken(),
  },
  actions: {
    // TODO: add initializeAuth
    setToken(token: string) {
      tokenManager.setToken(token)
    },
    clearToken() {
      tokenManager.clearToken()
    },
    async login(email: string) {
      try {
        const initiateLogin = await login(email)
        if (initiateLogin.success && initiateLogin.data.otpSent) {
          return { success: true, message: 'OTP has been sent to your email.' }
        }

        const msg = initiateLogin.message.length == 0 ? initiateLogin.message : initiateLogin.error
        return { success: false, message: msg }
      } catch (err: any) {
        if (err instanceof ApiError) {
          if (err.statusCode == 404) {
            return { success: false, message: 'No account found, please register first.' }
          } else {
            return { success: false, message: err.data?.message ?? err.message }
          }
        }
        return { success: false, message: err.message }
      }
    },
    async verifyOtpAndLogin(email: string, otp: string) {
      try {
        const verifyLoginResp = await verifyLogin(email, otp)
        if (verifyLoginResp.success && verifyLoginResp.data.token) {
          this.token = verifyLoginResp.data.token
          this.setToken(verifyLoginResp.data.token)
          return { success: true, message: 'Successfully logged in.' }
        }
        const msg = verifyLoginResp.message.length == 0 ? verifyLoginResp.message : verifyLoginResp.error
        return { success: false, message: msg }
      } catch (err: any) {
        if (err instanceof ApiError) {
          return { success: false, message: err.data?.message ?? err.message }
        }
        return { success: false, message: err.message }
      }
    },

    async register(payload: { email: string; name: string; referralCode: string; residence: string }) {
      try {
        const registerResp = await registerUser(payload)
        if (registerResp.success && registerResp.data.registered) {
          return { success: true, message: 'Successfully Registered! A verification email has been sent to you.' }
        }
        const msg = registerResp.message.length == 0 ? registerResp.message : registerResp.error
        return { success: false, message: msg }
      } catch (err: any) {
        if (err instanceof ApiError) {
          if (err.statusCode == 409) {
            return { success: false, message: 'Account with same email already exists.' }
          } else {
            return { success: false, message: err.data?.message ?? err.message }
          }
        }
        return { success: false, message: err.message }
      }
    },

    async verifyEmailInStore(token: string) {
      try {
        const verifyEmailResp = await verifyEmail(token)
        if (verifyEmailResp.success && verifyEmailResp.data.verifiedEmail) {
          return { success: true, message: 'Successfully Verified Email! You will be redirected to login.' }
        }
        const msg = verifyEmailResp.message.length == 0 ? verifyEmailResp.message : verifyEmailResp.error
        return { success: false, message: msg }
      } catch (err: any) {
        if (err instanceof ApiError) {
          return { success: false, message: err.data?.message ?? err.message, data: null }
        }
        return { success: false, message: err.message, data: null }
      }
    },

    async logout() {
      try {
        const logoutResp = await logoutUser()
        if (logoutResp.success && logoutResp.data) {
          this.clearToken()

          return { success: true, message: 'Successfully Registered! A verification email has been sent to you.' }
        }
        const msg = logoutResp.message.length == 0 ? logoutResp.message : logoutResp.error
        return { success: false, message: msg }
      } catch (err: any) {
        if (err instanceof ApiError) {
          return { success: false, message: err.data?.message ?? err.message }
        }
        return { success: false, message: err.message }
      }
    },
  },
})
